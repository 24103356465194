import React from 'react'
import Layout from '../components/Layout'

export default function NotFound() {
    return (
        <div>
            <Layout>
            <h1>404 not found !</h1>
            </Layout>
        </div>
    )
}
